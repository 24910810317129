<template>
	<center class="pt-6">
		<h4>Toggle story feature</h4>
		<label class="switch">
			<input v-model="checked" @change="toggleStory" type="checkbox" />
			<span class="slider round"></span>
		</label>
	</center>
</template>

<script>
import { extractErrorMessage } from '@/utils/helpers'
export default {
  name: 'Story',
  data () {
    return {
      checked: ''
    }
  },
  created () {
    this.getStoryStatus()
  },
  methods: {
    toggleStory () {
      if (this.checked) {
        this.$swal({
          title: 'Are you sure?',
          text: 'You are about to turn on the story feature on the customer mobile app!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Proceed!'
        }).then((result) => {
          if (result.isConfirmed) {
            result.isConfirmed = true
            this.handleSubscription(result.isConfirmed)
          } else {
            this.checked = false
          }
        })
      }

      if (!this.checked) {
        this.$swal({
          title: 'Are you sure',
          text: 'You are about to turn off the story feature on the customer mobile app!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Proceed!'
        }).then((result) => {
          if (result.isConfirmed) {
            result.isConfirmed = false
            this.handleSubscription(result.isConfirmed)
          } else {
            this.checked = true
          }
        })
      }
    },

    async getStoryStatus () {
      try {
        const response = await this.axios.get('/v1/stories/visibility')
        if (response.data.enabled) {
          this.checked = true
        } else {
          this.checked = false
        }
      } catch (error) {
        const msg = extractErrorMessage(
          error,
          'An error occurred, please try again.'
        )
        this.$swal().showValidationMessage(msg)
      }
    },

    async handleSubscription (value) {
      try {
        await this.axios.post('/v1/stories/visibility', {
          enabled: value
        })
        if (value == true) {
          this.$swal(
            'Success!',
            'Story feature was successfully turned on',
            'success'
          )
          this.checked = true
        } else {
          this.$swal(
            'Success!',
            'Story feature was successfully turned off',
            'success'
          )
          this.checked = false
        }
      } catch (error) {
        if (value == true) {
          const msg = extractErrorMessage(
            error,
            'An error occurred, please try again.'
          )
          this.$swal().showValidationMessage(msg)
          this.checked = false
        } else {
          const msg = extractErrorMessage(
            error,
            'An error occurred, please try again.'
          )
          this.checked = true
          this.$swal().showValidationMessage(msg)
        }
      }
    }
  }
}
</script>

<style scoped>
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
